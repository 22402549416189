<template>
  <div>

    <div class="platform-buttom-list">
      <div
        :class="tagType===1? 'bottom-button-active':'bottom-button'"
        v-if="isAuth('inspection_index_check')"
        @click="handleChangeTag(1)"
      >质检</div>
      <div
        :class="tagType===2? 'bottom-button-active':'bottom-button'"
        v-if="isAuth('inspection_index_checkamount')"
        @click="handleChangeTag(2)"
      >对账</div>
    </div>

    <component :is="currentComponent"></component>

  </div>
</template>

<script>
export default {
  components: {
    CheckIndex: () => import('./check/CheckIndex.vue'),
    ReconciliationList: () => import('./reconciliation/ListReconciliation.vue')
  },
  data () {
    return {
      tagType: 1,
      currentComponent: 'CheckIndex'
    }
  },
  created () {
    if (this.isAuth('inspection_index_check')) {
      this.tagType = 1
      this.currentComponent = 'CheckIndex'
    } else {
      this.tagType = 2
      this.currentComponent = 'ReconciliationList'
    }
  },
  methods: {
    handleChangeTag (tag) {
      this.tagType = tag
      switch (this.tagType) {
        case 1:
          this.currentComponent = 'CheckIndex'
          break
        case 2:
          this.currentComponent = 'ReconciliationList'
          break
      }
    }
  }
}
</script>
